/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import Headroom from 'headroom.js';
import Bloodhound from 'corejs-typeahead';

//import 'bootstrap/js/transition';
//import 'bootstrap/js/alert';
//import 'bootstrap/js/button';
//import 'bootstrap/js/carousel';
//import 'bootstrap/js/collapse';
//import 'bootstrap/js/dropdown';
//import 'bootstrap/js/modal';
//import 'bootstrap/js/tooltip';
//import 'bootstrap/js/popover';
//import 'bootstrap/js/scrollspy';
//import 'bootstrap/js/tab';
//import 'bootstrap/js/affix';

$(async function () {
	// Selectize
	var selects = $('select[multiple]');
	if (selects.length) {
		await import('selectize');

		selects.selectize({
			plugins: ['remove_button'],
			closeAfterSelect: true
		});
	}

	// Lightslider
	$('.slideshow:has(:nth-child(2))').each(async function () {
		await import('lightslider');

		var element = $(this);
		var cssClass = element.attr('class');
		element.removeClass(cssClass);

		element.lightSlider({
			item: 1,
			slideMargin: 0,
			addClass: cssClass,
			mode: 'fade',
			auto: true,
			pauseOnHover: true,
			loop: true,
			pause: 8000,
			controls: true,
			adaptiveHeight: true,
			pager: true,
			gallery: false,
			galleryMargin: 0
		});
	});

	$('.product .image:has(:nth-child(2))').each(async function () {
		await import('lightslider');

		var element = $(this);
		var cssClass = element.attr('class');
		element.removeClass(cssClass);

		element.lightSlider({
			item: 1,
			slideMargin: 0,
			addClass: cssClass,
			mode: 'fade',
			auto: true,
			pauseOnHover: true,
			loop: true,
			pause: 4000,
			controls: false,
			pager: true,
			gallery: false,
			galleryMargin: 0,
			onSliderLoad: function () {
				element.css({
					height: '',
					paddingBottom: ''
				});
			}
		});
	});

	$('.slider:has(:nth-child(2))').each(async function () {
		await import('lightslider');

		var element = $(this);
		var cssClass = element.attr('class');
		element.removeClass(cssClass);

		element.lightSlider({
			item: 7,
			slideMargin: 0,
			addClass: cssClass,
			auto: true,
			pauseOnHover: true,
			loop: true,
			pause: 2000,
			controls: true,
			adaptiveHeight: false,
			pager: false,
			gallery: false,
			galleryMargin: 0,
			responsive: [
				{
					breakpoint: 1920,
					settings: {
						item: 5
					}
				}, {
					breakpoint: 1440,
					settings: {
						item: 4
					}
				}, {
					breakpoint: 1280,
					settings: {
						item: 3
					}
				}, {
					breakpoint: 980,
					settings: {
						item: 2
					}
				}, {
					breakpoint: 640,
					settings: {
						item: 1
					}
				}
			]
		});

		// Move controls to outer container
		element.next('.lSAction').appendTo(element.closest('.lSSlideOuter'));
	});

	// Header affix
	$('header').each(function () {
		var $this = $(this);
		var height = $this.outerHeight();

		var headroom = new Headroom(this, {
			offset: height
		}).init();

		if (headroom) {
			$this.parent().css('padding-top', height);

			$this.find('.navbar-collapse').on('show.bs.collapse', function () {
				$this.addClass('navbar-open');
			}).on('hide.bs.collapse', function () {
				$this.removeClass('navbar-open');
			});
		}
	});

	// Equal height filter
	$('#container .filter').each(async function () {
		await import('jquery-match-height');

		var $this = $(this).css('height', 'auto');
		$this.siblings().add($this).matchHeight({
			property: 'min-height'
		});
		$this.css('height', '');
	});

	// Search typeahead
	$('form.search-form input[type=text]').typeahead(null, {
		name: 'search',
		limit: 10,
		source: new Bloodhound({
			datumTokenizer: Bloodhound.tokenizers.whitespace,
			queryTokenizer: Bloodhound.tokenizers.whitespace,
			remote: {
				url: '/umbraco/api/search/products?query=%QUERY',
				wildcard: '%QUERY'
			}
		})
	}).on('typeahead:select', function (e, value, dataset) {
		$(this).closest('form').submit();
	}).attr('autocomplete', 'off');

	// Product filter typeahead
	$('form.filter .product-name input[type=text]').each(function () {
		var element = $(this),
			form = element.closest('form'),
			categoryId = form.data('category-id');

		element.typeahead(null, {
			name: 'filter',
			limit: 10,
			source: new Bloodhound({
				datumTokenizer: Bloodhound.tokenizers.whitespace,
				queryTokenizer: Bloodhound.tokenizers.whitespace,
				remote: {
					url: '/umbraco/api/search/products?query=%QUERY&categoryId=' + categoryId,
					wildcard: '%QUERY'
				}
			})
		}).on('typeahead:select', function (e, value, dataset) {
			$(this).closest('form').submit();
		}).attr('autocomplete', 'off');
	});

	// Paged products
	var pagedProducts = $('.paged-products');
	if (pagedProducts.length) {
		await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/jquery.waypoints.js');
		await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/shortcuts/infinite.js');

		pagedProducts.each(function () {
			var products = $(this);
			products.find('a[rel=next]').one('click', function (e) {
				var infinite = new Waypoint.Infinite({
					element: products[0],
					container: products.find('.products'),
					items: '.column',
					more: 'a[rel=next]',
					offset: function () {
						return Waypoint.offsetAliases['bottom-in-view'].call(this) + 200;
					},
					onAfterPageLoad: function (items) {
						// Fade in added items
						const delay = 40;
						items.css('opacity', 0).each(function (i) {
							var item = $(this);
							setTimeout(function () {
								item.css({
									opacity: 1,
									transition: 'opacity ease 120ms'
								});
							}, i * delay);
						});
					}
				});

				products.find('.pagination-container').hide();
				e.preventDefault();
			});
		});
	}

	// Jump to submitted form
	$('.umbraco-forms-form[id]:has(form)').each(function () {
		var umbForm = $(this), form = umbForm.find('form');
		form.attr('action', form.attr('action') + '#' + umbForm.attr('id'));
	});
});
